<template>
  <div class="container-select bg-white select-wrapper">
    <section id="knowledge-base-content">
      <!-- ÇIKIŞ YAP -->
      <div class="btn btn-danger btn-position custom-shadow" @click="logout">
        {{ $t("Exit") }}
      </div>
      <!-- SEÇİM YAPINIZ -->
      <b-row class="kb-search-content-info match-height container-card-row">
        <div class="mt-5 mb-2">
          <p class="mt-2 text-center person-login-text">
            {{$t('Welcome')}}, {{loginList[0].name}} {{loginList[0].last_name}}
          </p>
          <p class="mt-2 text-center text-primary select-login-text">
            {{ $t("Please Select to Login") }}
          </p>
        </div>
      </b-row>

      <b-row
        class="
          d-flex
          justify-content-center
          align-items-center
          container-row
        "
      >
      <!--  GİRİŞ SEÇENEKLERİ, KARTLAR -->
        <b-col
          v-for="loginCard in loginList"
          :key="loginCard.company_branch_person_role_id"
          class="loginCol"
          md="4"
          sm="6"
        >
          <b-card
            class="custom-shadow login-card card"
            @click="setInfo(loginCard)"
          >
            <b-row
              class="card-header bg-primary rounded text-white custom-shadow"
            >
              <b-col>
                <template
                  v-if="
                    loginCard.company_type_id ===
                    '414da4c3-54bf-4671-98d9-f4212f0ba575'
                  "
                >
                <h4 class="text-white">AVV</h4>
                </template>
                <template v-else>
                  <b class="text-white">{{ $t("Facility") }}:</b>
                  {{ loginCard.company_branch_name }}
                </template>
              </b-col>
            </b-row>
            <b-row
              class="mt-1 d-flex justify-content-center align-items-center"
            >
              <b-col cols="8">
                <div>
                  <b>{{ $t("Your Role") }}:</b> {{ loginCard.group_name }}
                </div>
              </b-col>
              <b-col
                cols="4"
                class="d-flex justify-content-end align-items-center"
              >
                <span v-for="(image, index) in imageList" :key="index">
                  <b-avatar
                    v-if="
                      image.menu_role_group_id === loginCard.menu_role_group_id
                    "
                    class="custom-shadow bg-avatar"
                    variant="info"
                    :src="image.url"
                    size="5rem"
                  ></b-avatar>
                </span>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            v-if="false"
            class="
              text-center
              cursor-pointer
              custom-login-card custom-login-card-shadow
            "
            @click="setInfo(loginCard)"
          >
            <div class="custom-flex">
              <b-card-text class="mt-1 shadow p-1 rounded"> </b-card-text>
              <b-card-text class="mt-1 shadow p-1 rounded">
                <b>{{ $t("Your Role") }}:</b> {{ loginCard.group_name }}
              </b-card-text>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";


import {
  BBadge,
  BCardText,
  BRow,
  BImg,
  BCol,
  BButton,
  BButtonGroup,
  BCard,
  BForm,
  BFormGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BLink,
  BCardTitle,
  BFormInput,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BBadge,
    BCardText,
    BRow,
    BImg,
    BCol,
    BButton,
    BButtonGroup,
    BCard,
    BForm,
    BFormGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    VuexyLogo,
    BLink,
    BCardTitle,
    BFormInput,
    BAvatar,
  },
  data() {
    return {
      imageList: {
        customer: {
          menu_role_group_id: "5be25ecb-1a4a-43fb-9bd2-bca6b23d4d10",
          url: "https://img.icons8.com/color/2x/gender-neutral-user.png",
        },
        trainer: {
          menu_role_group_id: "ceb6fb46-946e-4e07-84a2-3431b608d1c7",
          url: "https://img.icons8.com/color/2x/personal-trainer-skin-type-2.png",
        },
        faciltyManager: {
          menu_role_group_id: "7de48788-ba4d-44c7-b863-5158270c6488",
          url: "https://img.icons8.com/plumpy/452/manager.png",
        },
        distributor: {
          menu_role_group_id: "fd69984d-0471-47c0-8636-5bdcd2c3190b",
          url: "https://img.icons8.com/color-glass/2x/city-buildings.png"
        },
      },
      loginList: null,
    };
  },
  computed: {},
  methods: {
    getOptionList()
    {
      this.loginList = this.$store.getters["auth/optionList"]



      if (this.loginList.length === 0) {

        //birden fazla giriş seçeneği yoksa select logine zaten düşmez elle yazarsa da home'a ata
        this.$router.push({ name: "home" });
      }
      else if (sessionStorage.getItem("auth/userInfo"))
      {
        this.$router.push({ name: "home" }); //kişi bilgisi varsa tekrar select login ekranına dönemez
      }
    },
    setInfo(loginCard) {
      this.$store
        .dispatch("auth/userInfo", {
          name: loginCard.name,
          last_name: loginCard.last_name,
          person_id: loginCard.person_id,
          email: loginCard.email,
          menu_role_group_id: loginCard.menu_role_group_id,
          group_name: loginCard.group_name,
          company_branch_id: loginCard.company_branch_id,
          company_branch_name: loginCard.company_branch_name,
          company_branch_person_role_id:loginCard.company_branch_person_role_id,
        })
        .then(() => {
          this.$router.push({ name: "home" });
        });
    },
    logout() {
      this.$store.dispatch("auth/logout")
    },
  },
  created() {
    this.getOptionList();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";
@import "@core/scss/vue/pages/page-auth.scss";
.container-select {
  min-height: 100vh;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f0f0f0 !important;
}
.container-row {
  padding: 0;
  margin: 0;
  justify-content: left !important;
}
.container-card-row {
  padding: 0 !important;
  margin: 0 !important;
  align-items: center !important;
  justify-content: center !important;
}
.bg-avatar {
  background-color: #f0f0f0f0;
}
.custom-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.login-card:hover {
  border-color: #7367f0 !important;
  cursor: pointer;
}
.login-card {
  border: 0.2px solid rgb(175, 175, 175);
  display: block;
}
.please-select {
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px !important;
}
.btn-position {
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
}
.select-login-text {
  font-size: 28px;
}
.person-login-text{
  line-height: 1.2;
  color: #ad70d6 !important;
  font-size: 30px;
}
@media screen and(max-width: 500px) {
  .btn-position {
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
  }
  .please-select {
    margin-top: 75px !important;
    margin-left: 40px;
    margin-right: 40px;
  }
  .select-wrapper {
    background-image: url("../../assets/images/badge/bg-select-login.png") !important;
    background-position: left center !important;
  }
  .select-login-text {
    font-size: 16px;
    margin-bottom: 0;
  }
  .person-login-text{
    line-height: 1.2;
    color: #ad70d6 !important;
    font-size: 18px;
  }
}
.select-wrapper {
  background-image: url("../../assets/images/badge/bg-select-login.png") !important;
  background-position: center bottom;
  background-repeat: no-repeat;
}
@media screen and (min-width: 767.78px) {
  .loginCol{
    min-width: 400px !important;
  }
}
</style>
